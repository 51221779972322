:root {
  --color-primary: #020d18;
  --color-secondary: #dcf836;
  --color-red: #dd003f;
  --color-lite-white: #abb7c4;
  --color-navbar: #0f2133;
}

body {
  margin: 0px;
  padding: 0px;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.bg-red {
  background-color: var(--color-red) !important;
}

.bg-lite-white {
  background-color: var(--color-lite-white) !important;
}

.bg-navbar {
  background-color: var(--color-navbar) !important;
}

@media (max-width: 768px) {
  .slick-slider {
    margin-top: 3em;
  }
}

.movie-items .slick-slider {
  margin-left: 0px !important;
}

.slick-dots {
  position: absolute;
  top: -2.5em;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: end;
  right: 1em;
  pointer-events: none;
}

.slick-dots li {
  pointer-events: all;
  height: 10px;
  width: 10px;
}

.slick-dots li button {
  height: 10px !important;
  width: 10px !important;
}

.slick-dots li button:before {
  color: transparent;
  height: 10px;
  width: 10px;
}

.slick-dots li.slick-active button:before {
  color: transparent;
}

.movie-items .slick-slider {
  margin-left: 0px !important;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  display: flex;
}

.tabs .tab-content {
  padding-left: 0px !important;
  margin-top: 1em;
}

.page-single {
  background-color: #020d18;
  padding: 10em 0 0 0;
}

.movie-item-style-2 {
  align-items: flex-start !important;
}
